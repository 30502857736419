import "typeface-roboto";
import "../bootstrap";
import "../style.css";

import CssBaseline from "@mui/material/CssBaseline";
import type { Theme as MuiTheme } from "@mui/material/styles";
import {
  createTheme,
  darken,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Api, ApiProvider } from "../api";
import { AvatarOfFrost } from "../components/AvatarOfFrost";
import { createErrorBoundary } from "../components/createErrorBoundary";
import { LoadingBlock } from "../components/LoadingBlock";
import { assertNever, noop } from "../lib/utils";
import { RefreshErrorHandler } from "../scenes";
import { useStoredApiToken } from "../store/apiToken";
import { Theme, useStoredTheme } from "../store/theme";

const themeDark = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 1,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          "&$focusVisible": {
            backgroundColor: "hsl(195, 15%, 40%)",
          },
          "&:hover": {
            backgroundColor: "hsl(195, 15%, 40%)",
          },
          backgroundColor: "hsl(195, 15%, 30%)",
          color: "#FFF",
        },
      },
    },
  },
  palette: {
    background: {
      default: "hsl(195, 25%, 3%)",
      paper: "hsl(195, 25%, 6%)",
    },
    primary: {
      contrastText: "#000",
      dark: "hsl(195, 15%, 50%)",
      light: "hsl(195, 50%, 85%)",
      main: "hsl(195, 30%, 75%)",
    },
    secondary: {
      contrastText: "#FFF",
      dark: "hsl(25, 25%, 40%)",
      light: "hsl(25, 25%, 50%)",
      main: "hsl(25, 25%, 45%)",
    },
    mode: "dark",
  },
});

const themeLight = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "hsl(195, 15%, 90%)",
          zIndex: 1,
        },
      },
    },
  },
  palette: {
    background: {
      default: "hsl(195, 15%, 95%)",
      paper: "hsl(195, 15%, 98%)",
    },
    primary: {
      contrastText: "#FFF",
      dark: "hsl(200, 50%, 30%)",
      light: "hsl(200, 50%, 65%)",
      main: "hsl(200, 50%, 50%)",
    },
    secondary: {
      contrastText: "#FFF",
      dark: "hsl(35, 50%, 40%)",
      light: "hsl(35, 50%, 60%)",
      main: "hsl(35, 50%, 50%)",
    },
    mode: "light",
  },
});

function useTheme(theme: Theme): MuiTheme {
  const preferDarkTheme = useMediaQuery("@media (prefers-color-scheme: dark)");

  switch (theme) {
    case Theme.SystemDefault:
      return preferDarkTheme ? themeDark : themeLight;
    case Theme.Dark:
      return themeDark;
    case Theme.Light:
      return themeLight;
    default:
      return assertNever(theme);
  }
}

const ErrorBoundary = createErrorBoundary();

export default function MyApp({ Component, pageProps }: AppProps) {
  const [selectedTheme] = useStoredTheme();
  const theme = useTheme(selectedTheme);
  const [apiToken] = useStoredApiToken();
  const api = useMemo(() => {
    if (typeof apiToken !== "undefined") {
      return new Api({
        apiKey: apiToken,
        basePath: CONFIG.apiBaseUrl,
      });
    } else {
      return new Api({ basePath: CONFIG.apiBaseUrl });
    }
  }, [apiToken]);

  const themeColor = darken(theme.palette.background.default, 0.2);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const globalStyles = useMemo(() => {
    const {
      palette: { action, background, error, primary, secondary, text },
      shadows,
      spacing,
    } = theme;

    return `
      :root {
        --mui-shadows-0: ${shadows[0]};
        --mui-shadows-1: ${shadows[1]};
        --mui-shadows-2: ${shadows[2]};
        --mui-shadows-3: ${shadows[3]};
        --mui-shadows-4: ${shadows[4]};
        --mui-palette-action-activatedOpacity: ${action.activatedOpacity};
        --mui-palette-action-active: ${action.active};
        --mui-palette-action-disabled: ${action.disabled};
        --mui-palette-action-disabledBackground: ${action.disabledBackground};
        --mui-palette-action-disabledOpacity: ${action.disabledOpacity};
        --mui-palette-action-focus: ${action.focus};
        --mui-palette-action-focusOpacity: ${action.focusOpacity};
        --mui-palette-action-hover: ${action.hover};
        --mui-palette-action-hoverOpacity: ${action.hoverOpacity};
        --mui-palette-action-selected: ${action.selected};
        --mui-palette-action-selectedOpacity: ${action.selectedOpacity};
        --mui-palette-background-default: ${background.default};
        --mui-palette-background-paper: ${background.paper};
        --mui-palette-error-main: ${error.main};
        --mui-palette-primary-contrastText: ${primary.contrastText};
        --mui-palette-primary-dark: ${primary.dark};
        --mui-palette-primary-light: ${primary.light};
        --mui-palette-primary-main: ${primary.main};
        --mui-palette-secondary-contrastText: ${secondary.contrastText};
        --mui-palette-secondary-dark: ${secondary.dark};
        --mui-palette-secondary-light: ${secondary.light};
        --mui-palette-secondary-main: ${secondary.main};
        --mui-palette-text-disabled: ${text.disabled};
        --mui-palette-text-primary: ${text.primary};
        --mui-palette-text-secondary: ${text.secondary};
        --mui-spacing: ${spacing()};
      }
    `;
  }, [theme]);

  useEffect(() => {
    const body = document.body;

    if (theme.palette.mode === "dark") {
      body.classList.add("dark");

      return () => {
        body.classList.remove("dark");
      };
    } else {
      return noop;
    }
  }, [theme.palette.mode]);

  return (
    <>
      <Head>
        <title>Skylords Reborn</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content={themeColor} />
        <style dangerouslySetInnerHTML={{ __html: globalStyles }} />
      </Head>
      <ErrorBoundary errorComponent={RefreshErrorHandler}>
        <ApiProvider value={api}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AvatarOfFrost className="background" />
              {isClient ? (
                <Suspense fallback={<LoadingBlock />}>
                  <Component {...pageProps} />
                </Suspense>
              ) : (
                <LoadingBlock />
              )}
            </ThemeProvider>
          </StyledEngineProvider>
        </ApiProvider>
      </ErrorBoundary>
    </>
  );
}
