import type { ComponentType } from "react";
import { Component } from "react";

function isPromiseAlike(value: unknown): value is PromiseLike<unknown> {
  return (
    value !== null &&
    typeof value === "object" &&
    typeof (value as any).then === "function"
  );
}

export interface ErrorBoundaryErrorComponentProps {
  error: unknown;
  onResetError(): void;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  errorComponent: ComponentType<ErrorBoundaryErrorComponentProps>;
}

interface ErrorBoundaryState {
  error: unknown | undefined;
  hasError: boolean;
}

export function createErrorBoundary(
  matches: (error: unknown) => boolean = () => true
) {
  /**
   * General ErrorBoundary to recover application from unhandled exceptions.
   */
  return class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
  > {
    public state = { error: undefined, hasError: false };

    constructor(props: ErrorBoundaryProps) {
      super(props);

      this.handleResetError = this.handleResetError.bind(this);
    }

    public static getDerivedStateFromError(error: unknown) {
      if (isPromiseAlike(error) || !matches(error)) {
        throw error;
      }

      return { error, hasError: true };
    }

    public render() {
      const { errorComponent: ErrorComponent, children } = this.props;
      const { error, hasError } = this.state;

      if (hasError) {
        return (
          <ErrorComponent error={error} onResetError={this.handleResetError} />
        );
      } else {
        return children;
      }
    }

    private handleResetError() {
      this.setState({
        error: undefined,
        hasError: false,
      });
    }
  };
}
