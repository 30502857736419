import {
  AccountControllerApi,
  AntiCheatLogControllerApi,
  BanControllerApi,
  BoosterControllerApi,
  CardControllerApi,
  ChatControllerApi,
  Configuration,
  ConnectionLogControllerApi,
  Cosmetic,
  CosmeticControllerApi,
  DeckControllerApi,
  MailControllerApi,
  ReportControllerApi,
  TransactionControllerApi,
  UserControllerApi,
} from "@skylords/api-client";
import type { FetchAPI } from "@skylords/api-client";
import DataLoader from "dataloader";
import { LRUMap } from "lru_map";
import pLimit from "p-limit";
import { terminateSession } from "../lib/sessionManagement";
import type { Account } from "./Account";
import type { AntiCheatLog, AntiCheatLogType } from "./AntiCheatLog";
import type { ChangeBanStatus } from "./ChangeBanStatus";
import type { Chat } from "./Chat";
import type { ChatMessage } from "./ChatMessage";
import type { ConnectionLog } from "./ConnectionLog";
import type { Deck } from "./Deck";
import type { Booster } from "./Booster";
import type { Report, ReportStatus } from "./Report";
import type { Card } from "./Card";
import type { Transaction, TransactionType } from "./Transaction";
import type { Mail } from "./Mail";

/**
 * This function is used to fix up Dates in the API objects.
 *
 * While our API generator generates the proper TS typings for Dates
 * returned by the backend, it does not deserialize them.
 * We use this function to fix that.
 *
 * It takes the object to be fixed as the first parameter and a list
 * of properties to fix as the second.
 *
 * While the typings of this function prevent the user from adding
 * the wrong properties, they do not enforce that all have been passed.
 *
 * We hope this will get fixed in a future version of the generator
 * and this will no longer be needed. The function is written in a
 * way to not break, when the error gets fixed upstream.
 *
 * This function is not needed for Date objects being *send to*
 * the backend only for objects *received by* it.
 */
function fixDate<T>(
  object: T,
  dateProps: {
    [K in keyof T]: Date extends T[K] ? K : never;
  }[keyof T][]
): T {
  const clone = { ...object };

  for (const key of dateProps) {
    const value = clone[key] as unknown as string | Date | undefined | null;

    if (typeof value === "string") {
      clone[key] = new Date(value) as any;
    }
  }

  return clone;
}

interface ApiOptions {
  readonly apiKey?: string;
  readonly basePath: string;
}

interface GetAccountsFilters {
  readonly forumId?: number;
  readonly id?: number;
  readonly nameStartsWith?: string;
}

interface GetAccountsOptions {
  readonly filters?: GetAccountsFilters;
  readonly limit?: number;
  readonly offset?: number;
  readonly sort?: readonly ["name", "asc" | "desc" | undefined][];
}

interface GetAntiCheatLogsFilters {
  readonly accountId?: number;
  readonly typeIn?: AntiCheatLogType[];
}

interface GetAntiCheatLogsOptions {
  readonly filters?: GetAntiCheatLogsFilters;
  readonly limit?: number;
  readonly offset?: number;
  readonly sort?: readonly ["detectionTime", "asc" | "desc" | undefined][];
}

interface GetChatsFilters {
  readonly lastSendTimeGe?: Date;
  readonly lastSendTimeLe?: Date;
  readonly participantIdsHas?: number;
}

interface GetChatsOptions {
  readonly filters?: GetChatsFilters;
  readonly limit?: number;
  readonly sort?: readonly ["lastSendTime", "asc" | "desc" | undefined][];
}

interface GetChatMessagesFilters {
  readonly sendTimeGe?: Date;
  readonly sendTimeLe?: Date;
}

interface GetChatMessagesOptions {
  readonly chatId: string;
  readonly filters?: GetChatMessagesFilters;
  readonly limit?: number;
  readonly sort?: ["sendTime", "asc" | "desc" | undefined][];
}

interface GetConnectionLogsFilters {
  readonly accountId?: number;
  readonly hwidMatch?: string;
  readonly ip?: string;
}

interface GetConnectionLogsOptions {
  readonly filters?: GetConnectionLogsFilters;
  readonly limit?: number;
  readonly offset?: number;
  readonly sort?: readonly ["lastUsed", "asc" | "desc" | undefined][];
}

interface GetDecksFilters {
  readonly ownerId?: number;
}

interface GetDecksOptions {
  readonly filters?: GetDecksFilters;
  readonly limit?: number;
  readonly offset?: number;
}

interface GetMailsFilters {
  readonly sendTimeGe?: Date;
  readonly sendTimeLe?: Date;
  readonly participantIdsHas?: number[];
}

interface GetMailsOptions {
  readonly filters?: GetMailsFilters;
  readonly limit?: number;
  readonly sort?: readonly ["sendTime", "asc" | "desc" | undefined][];
}

interface GetReportsFilters {
  readonly reporteeId?: number;
  readonly reporterId?: number;
  readonly status?: ReportStatus;
}

interface GetReportsOptions {
  readonly filters?: GetReportsFilters;
  readonly limit?: number;
  readonly offset?: number;
  readonly sort?: readonly ["reportTime", "asc" | "desc" | undefined][];
}

interface GetTransactionsFilters {
  readonly participantIdsHas?: [number] | [number, number | undefined];
  readonly timeOfTransactionGe?: Date;
  readonly timeOfTransactionLe?: Date;
  readonly typeIn?: TransactionType[];
}

interface GetTransactionsOptions {
  readonly filters?: GetTransactionsFilters;
  readonly limit?: number;
  readonly sort?: readonly ["timeOfTransaction", "asc" | "desc" | undefined][];
}

interface UpdateAccountOptions {
  readonly id: number;
  readonly name?: string;
}

interface UpdateDeckOptions {
  readonly id: number;
  readonly name?: string;
}

interface UpdateReportOptions {
  readonly description?: string;
  readonly id: number;
  readonly status?: ReportStatus;
}

export class Api {
  readonly #accountController: AccountControllerApi;
  readonly #antiCheatLogController: AntiCheatLogControllerApi;
  readonly #banController: BanControllerApi;
  readonly #boosterController: BoosterControllerApi;
  readonly #cardController: CardControllerApi;
  readonly #chatController: ChatControllerApi;
  readonly #connectionLogController: ConnectionLogControllerApi;
  readonly #cosmeticController: CosmeticControllerApi;
  readonly #deckController: DeckControllerApi;
  readonly #mailController: MailControllerApi;
  readonly #reportController: ReportControllerApi;
  readonly #transactionController: TransactionControllerApi;
  readonly #userController: UserControllerApi;

  constructor(options: ApiOptions) {
    const { apiKey, basePath } = options;

    const configuration = new Configuration({
      basePath,
    });

    const myFetch: FetchAPI = async (url, options) => {
      const response = await fetch(
        url,
        typeof apiKey === "undefined"
          ? options
          : {
              ...options,
              headers: {
                ...options.headers,
                Authorization: `Bearer ${apiKey}`,
              },
            }
      );

      if (response.status === 401) {
        terminateSession();
      }

      return response;
    };

    this.#accountController = new AccountControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#antiCheatLogController = new AntiCheatLogControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#banController = new BanControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#boosterController = new BoosterControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#cardController = new CardControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#chatController = new ChatControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#connectionLogController = new ConnectionLogControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#cosmeticController = new CosmeticControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#deckController = new DeckControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#mailController = new MailControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#reportController = new ReportControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#transactionController = new TransactionControllerApi(
      configuration,
      undefined,
      myFetch
    );
    this.#userController = new UserControllerApi(
      configuration,
      undefined,
      myFetch
    );
  }

  async changeBanStatus(changeBanStatus: ChangeBanStatus): Promise<void> {
    await this.#banController.banControllerChangeBanStatus(changeBanStatus);
  }

  readonly #accountFetchLimit = pLimit(16);
  readonly #accountLoader = new DataLoader(
    async (ids: readonly number[]) => {
      const brokenAccounts = await Promise.all(
        ids.map((id) =>
          this.#accountFetchLimit(() =>
            this.#accountController.accountControllerFindById(id)
          )
        )
      );

      return brokenAccounts.map((brokenAccount) =>
        fixDate(brokenAccount, [
          "lastBoughtCheaperBooster",
          "lastFreePvpDecksReroll",
          "lastOnline",
          "lastQuestGet",
          "lastQuestReroll",
          "lastSelectedDailyQuestPoolChange",
        ])
      );
    },
    {
      batchScheduleFn: (callback) => setTimeout(callback, 128),
      cacheMap: new LRUMap(4_096),
      maxBatchSize: 128,
    }
  );

  async getAccountById(id: number): Promise<Account> {
    const brokenAccount =
      await this.#accountController.accountControllerFindById(id);
    const account = fixDate(brokenAccount, [
      "lastBoughtCheaperBooster",
      "lastFreePvpDecksReroll",
      "lastOnline",
      "lastQuestGet",
      "lastQuestReroll",
      "lastSelectedDailyQuestPoolChange",
    ]);

    this.#accountLoader.clear(id);
    this.#accountLoader.prime(id, account);

    return account;
  }

  async getCachedAccountById(id: number): Promise<Account> {
    return this.#accountLoader.load(id);
  }

  async getAccounts(options?: GetAccountsOptions): Promise<Account[]> {
    const { filters, limit, offset, sort } = options ?? {};
    const { forumId, id, nameStartsWith } = filters ?? {};

    const brokenAccounts = await this.#accountController.accountControllerFind(
      forumId,
      id,
      nameStartsWith,
      limit,
      offset,
      sort?.map((v) => v.join(":")).join(",")
    );
    const accounts = brokenAccounts.map((brokenAccount) =>
      fixDate(brokenAccount, [
        "lastBoughtCheaperBooster",
        "lastFreePvpDecksReroll",
        "lastOnline",
        "lastQuestGet",
        "lastQuestReroll",
        "lastSelectedDailyQuestPoolChange",
      ])
    );

    for (const account of accounts) {
      this.#accountLoader.clear(account.id);
      this.#accountLoader.prime(account.id, account);
    }

    return accounts;
  }

  async getAntiCheatLogs(
    options: GetAntiCheatLogsOptions
  ): Promise<AntiCheatLog[]> {
    const { filters, limit, offset, sort } = options;
    const { accountId, typeIn } = filters ?? {};

    const antiCheatLogsWithoutRelations =
      await this.#antiCheatLogController.antiCheatLogControllerFind(
        accountId,
        typeIn as string[] | undefined,
        limit,
        offset,
        sort?.map((v) => v.join(":")).join(",")
      );

    return Promise.all(
      antiCheatLogsWithoutRelations.map(async (brokenAntiCheatLog) => {
        const antiCheatLog = fixDate(brokenAntiCheatLog, ["detectionTime"]);
        const account = await this.getCachedAccountById(antiCheatLog.accountId);

        return {
          ...antiCheatLog,
          account,
        };
      })
    );
  }

  #cachedBoosters: Promise<Booster[]> | undefined;

  async getBoosters(): Promise<Booster[]> {
    const boosters = this.#boosterController.boosterControllerFind();

    if (typeof this.#cachedBoosters === "undefined") {
      this.#cachedBoosters = boosters;
    }

    return boosters;
  }

  async getCachedBoosters(): Promise<Booster[]> {
    const cachedBooster = this.#cachedBoosters;

    if (typeof cachedBooster === "undefined") {
      return this.getBoosters();
    } else {
      return cachedBooster;
    }
  }

  #cachedCards: Promise<Card[]> | undefined;

  async getCards(): Promise<Card[]> {
    const cards = this.#cardController.cardControllerFind();

    if (typeof this.#cachedCards === "undefined") {
      this.#cachedCards = cards;
    }

    return cards;
  }

  async getCachedCards(): Promise<Card[]> {
    const cachedCards = this.#cachedCards;

    if (typeof cachedCards === "undefined") {
      return this.getCards();
    } else {
      return cachedCards;
    }
  }

  async getChats(options: GetChatsOptions): Promise<Chat[]> {
    const { filters, limit, sort } = options;
    const { lastSendTimeGe, lastSendTimeLe, participantIdsHas } = filters ?? {};

    const brokenChats = await this.#chatController.chatControllerFindChats(
      lastSendTimeGe,
      lastSendTimeLe,
      participantIdsHas,
      limit,
      sort?.map((v) => v.join(":")).join(",")
    );

    const chats = brokenChats.map((brokenChat) =>
      fixDate(brokenChat, ["lastSendTime"])
    );

    return chats;
  }

  async getChatMessages(
    options: GetChatMessagesOptions
  ): Promise<ChatMessage[]> {
    const { chatId, filters, limit, sort } = options;
    const { sendTimeGe, sendTimeLe } = filters ?? {};

    const chatsWithoutRelations =
      await this.#chatController.chatControllerFindChatMessages(
        chatId,
        sendTimeGe,
        sendTimeLe,
        limit,
        sort?.map((v) => v.join(":")).join(",")
      );

    return Promise.all(
      chatsWithoutRelations.map(async (brokenChatMessage) => {
        const chatMessage = fixDate(brokenChatMessage, ["sendTime"]);

        return {
          ...chatMessage,
          sender: await this.getCachedAccountById(chatMessage.senderId),
        };
      })
    );
  }

  async getConnectionLogs(
    options: GetConnectionLogsOptions
  ): Promise<ConnectionLog[]> {
    const { filters, limit, offset, sort } = options;
    const { accountId, hwidMatch, ip } = filters ?? {};

    const connectionLogsWithoutRelations =
      await this.#connectionLogController.connectionLogControllerFind(
        accountId,
        hwidMatch,
        ip,
        limit,
        offset,
        sort?.map((v) => v.join(":")).join(",")
      );

    return Promise.all(
      connectionLogsWithoutRelations.map(async (brokenConnectionLog) => {
        const connectionLog = fixDate(brokenConnectionLog, [
          "firstUsed",
          "lastUsed",
        ]);
        const account = await this.getCachedAccountById(
          connectionLog.accountId
        );

        return {
          ...connectionLog,
          account,
        };
      })
    );
  }

  #cachedCosmetics: Promise<Cosmetic[]> | undefined;

  async getCosmetics(): Promise<Cosmetic[]> {
    const cosmetics = this.#cosmeticController
      .cosmeticControllerFind()
      .then((cosmetics) => cosmetics.map((v) => fixDate(v, ["creationDate"])));

    if (typeof this.#cachedCosmetics === "undefined") {
      this.#cachedCosmetics = cosmetics;
    }

    return cosmetics;
  }

  async getCachedCosmetics(): Promise<Cosmetic[]> {
    const cachedCosmetics = this.#cachedCosmetics;

    if (typeof cachedCosmetics === "undefined") {
      return this.getCosmetics();
    } else {
      return cachedCosmetics;
    }
  }

  async getDeckById(id: number): Promise<Deck> {
    return await this.#deckController.deckControllerFindById(id);
  }

  async getDecks(options?: GetDecksOptions): Promise<Deck[]> {
    const { filters, limit, offset } = options ?? {};
    const { ownerId } = filters ?? {};

    return await this.#deckController.deckControllerFind(
      ownerId,
      limit,
      offset
    );
  }

  async getMails(options: GetMailsOptions): Promise<Mail[]> {
    const { filters, limit, sort } = options;
    const { sendTimeGe, sendTimeLe, participantIdsHas } = filters ?? {};

    const brokenMails = await this.#mailController.mailControllerFind(
      participantIdsHas,
      sendTimeGe,
      sendTimeLe,
      limit,
      sort?.map((v) => v.join(":")).join(",")
    );

    const mails = await Promise.all(
      brokenMails.map(async (brokenChat): Promise<Mail> => {
        const chat = fixDate(brokenChat, [
          "collectionTime",
          "deletionTime",
          "sendTime",
        ]);
        const [receiver, sender] = await Promise.all([
          this.getCachedAccountById(chat.receiverId),
          this.getCachedAccountById(chat.senderId),
        ]);

        return {
          ...chat,
          receiver,
          sender,
        };
      })
    );

    return mails;
  }

  #myCachedAccount: Promise<Account> | undefined;

  async getMyAccount(): Promise<Account> {
    const myAccount = this.#accountController
      .accountControllerFindMy()
      .then((brokenAccount) =>
        fixDate(brokenAccount, [
          "lastBoughtCheaperBooster",
          "lastFreePvpDecksReroll",
          "lastOnline",
          "lastQuestGet",
          "lastQuestReroll",
          "lastSelectedDailyQuestPoolChange",
        ])
      );

    if (typeof this.#myCachedAccount === "undefined") {
      this.#myCachedAccount = myAccount;
    }

    return myAccount;
  }

  async getMyCachedAccount(): Promise<Account> {
    const cachedAccount = this.#myCachedAccount;

    if (typeof cachedAccount === "undefined") {
      return this.getMyAccount();
    } else {
      return cachedAccount;
    }
  }

  async getReports(options: GetReportsOptions): Promise<Report[]> {
    const { filters, limit, offset, sort } = options;
    const { reporteeId, reporterId, status } = filters ?? {};
    const reportsWithoutRelations =
      await this.#reportController.reportControllerFind(
        reporteeId,
        reporterId,
        status,
        limit,
        offset,
        sort?.map((v) => v.join(":")).join(",")
      );

    return Promise.all(
      reportsWithoutRelations.map(async (brokenReport) => {
        const report = fixDate(brokenReport, ["reportTime"]);
        const [reportee, reporter] = await Promise.all([
          this.getCachedAccountById(report.reporteeId),
          this.getCachedAccountById(report.reporterId),
        ]);

        return {
          ...report,
          reportee,
          reporter,
        };
      })
    );
  }

  async getTransactions(
    options: GetTransactionsOptions
  ): Promise<Transaction[]> {
    const { filters, limit, sort } = options;
    const {
      timeOfTransactionGe,
      timeOfTransactionLe,
      typeIn,
      participantIdsHas,
    } = filters ?? {};

    const transactions =
      await this.#transactionController.transactionControllerFind(
        participantIdsHas?.filter((v) => v !== undefined) as
          | number[]
          | undefined,
        timeOfTransactionGe,
        timeOfTransactionLe,
        typeIn,
        limit,
        sort?.map((v) => v.join(":")).join(",")
      );

    return transactions.map((transaction) =>
      fixDate(transaction, ["timeOfTransaction"])
    );
  }

  async login(email: string, password: string): Promise<{ token: string }> {
    return this.#userController.userControllerLogin({
      email,
      password,
    });
  }

  async updateAccount(options: UpdateAccountOptions): Promise<void> {
    const { id, name } = options;

    await this.#accountController.accountControllerUpdateById(id, {
      name,
    });
  }

  async updateDeck(options: UpdateDeckOptions): Promise<void> {
    const { id, name } = options;

    await this.#deckController.deckControllerUpdateById(id, {
      name,
    });
  }

  async updateReport(options: UpdateReportOptions): Promise<void> {
    const { description, id, status } = options;

    await this.#reportController.reportControllerUpdateById(id, {
      description,
      status,
    });
  }
}
