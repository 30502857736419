import { useCallback, useEffect, useState } from "react";
import { setLocalStorage } from "./setLocalStorage";
import type { UpdateUseLocalStorageEventInit } from "./UpdateUseLocalStorageEventInit";

export function useLocalStorage(
  key: string
): [string | undefined, (value: string | undefined) => void] {
  const [value, setValue] = useState(
    typeof window === "undefined"
      ? undefined
      : // eslint-disable-next-line no-restricted-globals
        localStorage.getItem(key) ?? undefined
  );
  const mutate = useCallback(
    (newValue: string | undefined) => {
      setLocalStorage(key, newValue);
    },
    [key]
  );
  const handleStorageEvent = useCallback(
    (event: StorageEvent) => {
      if (
        // eslint-disable-next-line no-restricted-globals
        event.storageArea === localStorage &&
        event.key === key
      ) {
        setValue(event.newValue ?? undefined);
      }
    },
    [key]
  );
  const handleUpdateUseLocalStorageEvent = useCallback(
    (event: CustomEvent<UpdateUseLocalStorageEventInit>) => {
      if (event.detail.key === key) {
        setValue(event.detail.newValue);
      }
    },
    [key]
  );

  useEffect(() => {
    window.addEventListener("storage", handleStorageEvent);

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [handleStorageEvent]);

  useEffect(() => {
    // @ts-ignore
    window.addEventListener(
      "updateUseLocalStorage",
      handleUpdateUseLocalStorageEvent
    );

    return () => {
      // @ts-ignore
      window.removeEventListener(
        "updateUseLocalStorage",
        handleUpdateUseLocalStorageEvent
      );
    };
  }, [handleUpdateUseLocalStorageEvent]);

  return [value, mutate];
}
